<div [formGroup]="fastDuration" class="snake-form">

  <h1 class="title">Calculate Your Snake Hours</h1>

  <label class="input-start" for="fast-start">Start:
    <input [formControlName]="'start'"
           [value]="currentDate"
           type="datetime-local"
           id="fast-start"
           name="fast-start">

  </label>

  <label class="input-end" for="fast-end">End:
    <input [formControlName]="'end'"
           [value]="fastDuration.value.start"
           [disabled]="!fastDuration.value.start"
           type="datetime-local"
           id="fast-end"
           [min]="fastDuration.value.start"
           name="fast-end">
  </label>
  <h3 class="top-result">Total: {{!isNaN(fastingHours) && +fastingHours > 0 ? fastingHours : 0}}</h3>
  <h3 class="bottom-result">Snake Hours: {{!isNaN(snakeDietHours) && +snakeDietHours > 0 ? snakeDietHours : 0}}</h3>
</div>

